import { useUser } from 'zustand-stores/userEnvironmentStore';

export function useUserPostAListingHref() {
  const { user } = useUser();

  if (user && user.orgs.length === 1) {
    const org = user.orgs[0];
    if (org.isAllowedToPost && !org.isFrozen) {
      return `/admin/${org.id}/post-a-listing`;
    }

    return `/admin/${org.id}`;
  }

  return '/post-a-listing';
}
