import { ReactNode } from 'react';
import styled from 'styled-components';
import {
  BorderProps,
  BoxShadowProps,
  FlexboxProps,
  LayoutProps,
  MarginProps,
  PaddingProps,
  PositionProps,
  TextAlignProps,
} from 'styled-system';

import { Box } from 'components/Box/Box';
import { colors, transition } from 'theme/theme';

type Props = {
  id?: string;
  className?: string;
  children: ReactNode;
  $blueBorder?: boolean;
  $gradientBorder?: boolean;
};

export const Tile = styled(({ id, className, children, ...props }: Props) => (
  <Box
    id={id}
    className={className}
    borderRadius="7px"
    boxShadow="0 2px 4px rgba(0, 0, 0, 0.1)"
    bg="white"
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
  >
    {children}
  </Box>
))<
  PaddingProps &
    BoxShadowProps &
    PositionProps &
    LayoutProps &
    FlexboxProps &
    TextAlignProps &
    BorderProps &
    MarginProps
>`
  ${(props) => {
    if (props.$blueBorder) {
      return `
    position: relative;
    overflow: hidden;
    &::before {
      content: '';
      position: absolute;
      bottom: 100%;
      left: 0;
      transform: translateY(5px);
      width: 100%;
      height: 100%;
      background: ${colors.skyBlue};
      transition: all ${transition};
    }
  `;
    }

    if (props.$gradientBorder) {
      return `
    position: relative;
    overflow: hidden;
    &::before {
      content: '';
      position: absolute;
      bottom: 100%;
      left: 0;
      transform: translateY(10px);
      width: 100%;
      height: 10px;
      background-image: linear-gradient(
        to left,
        ${colors.skyBlue},
        ${colors.brandBlue}
      );
      background-size: 100% 10px;
      background-repeat: no-repeat;
      border-top-right-radius: 4px;
      border-top-left-radius: 4px;
    }
  `;
    }
  }}
`;
